import { css } from "@emotion/core";
import GraphikLight from "@fonts/Graphik/Graphik-Light.otf";
import GraphikRegular from "@fonts/Graphik/Graphik-Regular.otf";
import GraphikMedium from "@fonts/Graphik/Graphik-Medium.otf";
import GraphikBold from "@fonts/Graphik/Graphik-Bold.otf";
import GraphikSemibold from "@fonts/Graphik/Graphik-Semibold.ttf";

const Fonts = css`
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

  @font-face {
    font-display: swap;
    font-family: "Graphik";
    font-style: normal;
    font-weight: 300;
    src: url(${GraphikLight}) format("opentype");
  }

  @font-face {
    font-display: swap;
    font-family: "Graphik";
    font-style: normal;
    font-weight: normal;
    src: url(${GraphikRegular}) format("opentype");
  }

  @font-face {
    font-display: swap;
    font-family: "Graphik";
    font-style: normal;
    font-weight: 500;
    src: url(${GraphikMedium}) format("opentype");
  }

  @font-face {
    font-display: swap;
    font-family: "Graphik";
    font-style: normal;
    font-weight: 600;
    src: url(${GraphikSemibold}) format("truetype");
  }

  @font-face {
    font-display: swap;
    font-family: "Graphik";
    font-style: normal;
    font-weight: 700;
    src: url(${GraphikBold}) format("opentype");
  }
`;

export default Fonts;
