const bodyText = {
  color: "text",
  fontSize: "md",
  fontWeight: "normal",
  lineHeight: "1.5",
};

const heading = {
  color: "header",
  fontSize: ["2xl", null, "4xl"],
  fontWeight: "semibold",
  lineHeight: "1.4",
};

export const text = {
  body: {
    ...bodyText,
  },
  subHeaderBody: {
    ...bodyText,
    fontSize: ["md", null, "lg"],
  },
  inlineHeader: {
    ...bodyText,
    fontFamily: "heading",
    fontSize: ["lg", null, "2xl"],
    fontWeight: "semibold",
  },
  heading: {
    ...heading,
  },
  error: {
    color: "crimson",
    fontSize: "sm",
    mt: "5px",
  },
};
