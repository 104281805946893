export const fonts = {
  fonts: {
    heading: "Graphik,sans-serif",
    body: "'Roboto', sans-serif",
    nav: "Graphik,sans-serif",
  },
  fontSizes: {
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    "2xl": "24px",
    "3xl": "28px",
    "3.5xl": "32px",
    "4xl": "36px",
    "5xl": "48px",
    "6xl": "64px",
    "7xl": "72px",
  },
  textAlign: {
    left: "left",
    center: "center",
    right: "right",
  },
  fontWeights: {
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
};
