import React from "react";
import { ThemeProvider } from "emotion-theming";
import { Global } from "@emotion/core";
import { SearchProvider } from "@contexts/SearchContext";
import theme from "../theme";
import GlobalStyle from "../styles/GlobalStyles";

const ThemeLayout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <SearchProvider>
        <Global styles={GlobalStyle} />
        {children}
      </SearchProvider>
    </ThemeProvider>
  );
};

export default ThemeLayout;
