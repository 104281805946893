export const colors = {
  background: "#F2F2F2",
  banner: "#E3F5F2",
  black: "#000000",
  crimson: "crimson",
  header: "#2C3E50",
  newsletter: "#F5F7FA",
  newsletterAlt: "rgba(31, 198, 157, 0.1)",
  orange: "#e79924",
  primary: "#1DCF9F",
  purple: "#210f60",
  text: "#34495E",
  transparent: "transparent",
  white: "#ffffff",
};
