export const buttons = {
  primary: {
    bg: "primary",
    borderRadius: "100px",
    boxShadow: "button.primary",
    color: "white",
    cursor: "pointer",
    fontSize: ["sm", null, "md"],
    px: ["20px", null, "43px"],
    py: ["10px", null, "18px"],
    textDecoration: "none",
    transition: "all 0.2s",
    "&:visited": {
      color: "white",
    },
    "&:hover": {
      transform: "translateY(-2px)",
    },
  },
  outline: {
    bg: "transparent",
    borderColor: "primary",
    borderStyle: "solid",
    borderWidth: "1px",
    color: "primary",
    cursor: "pointer",
    fontSize: "md",
    px: ["13px", null, "28px"],
    py: ["11px", null, "18px"],
    transition: "all 0.2s",
    "&:visited": {
      color: "primary",
    },
    "&:hover": {
      bg: "primary",
      color: "white",
    },
  },

  newsletter: {
    bg: "primary",
    borderRadius: "4px",
    boxShadow: "button.newsletter",
    color: "white",
    cursor: "pointer",
    fontSize: "md",
    px: ["30px", null, "55px"],
    py: ["16px", null, "18px"],
    textDecoration: "none",
    "&:visited": {
      color: "white",
    },
  },
};
