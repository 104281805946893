import { breakpoints } from "./breakpoints";
import { colors } from "./colors";
import { fonts } from "./fonts";
import { mq } from "./media-query";
import { variants } from "./variants";

const theme = {
  ...fonts,
  ...variants,
  breakpoints: [...Object.values(breakpoints)],
  colors,
  mq,
};

export default theme;
