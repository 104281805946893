import { buttons } from "./buttons";
import { text } from "./text";

export const variants = {
  buttons,
  shadows: {
    button: {
      primary: "0px 10px 30px rgba(31, 198, 157, 0.2)",
      newsletter: "0px 2px 8px -1px rgba(4, 79, 58, 0.128005);",
    },
    card: "0px 4px 36px rgba(0, 0, 0, 0.073)",
  },
  text,
  variants: {
    card: {
      bg: "white",
      borderRadius: "10px",
      boxShadow: "card",
      p: ["1.8rem", null, "2.8rem"],
    },
    input: {
      bg: "white",
      border: "1px solid #E7E9EB",
      borderRadius: "4px",
      color: "text",
      flex: 1,
      px: ["14px", null, "16px"],
      py: ["16px", null, "18px"],
      "::placeholder": {
        color: "rgba(52, 73, 94, 0.5)",
      },
    },
  },
};
