import React, { createContext, useState, useMemo } from "react";

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [searchQuery, setQuery] = useState("");

  const value = useMemo(
    () => ({
      searchQuery,
      setQuery,
    }),
    [searchQuery, setQuery],
  );

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};
